<template>
  <div class="feature-container">
    <FeatureCard class="feature-item" icon="⚡" title="前端" detail="ご覧の通り"/>
    <FeatureCard class="feature-item" icon="✒️" title="后端" detail="C/C++(Qt), Java(Spring-boot), Rust, Python, VB"/>
    <FeatureCard class="feature-item" icon="🛠" title="部署" detail="Windows, Linux, Nginx"/>
  </div>
</template>
<script>
import FeatureCard from "@/components/FeatureCard.vue";

export default {
  name: "Features",
  components: {FeatureCard}
}
</script>

<style scoped>
.feature-container {
  display: flex;
  justify-content: center;
  align-items: stretch; /* 让子元素行高一致 */
  margin: auto;
}
.feature-item {
  width: calc(100% / 3);
  margin: 8px;
}
/* 平板 */
@media (max-width: calc(768px - 1px)) {
  .feature-container {
    flex-direction: column;
    align-items: center;
  }
  .feature-item {
    width: 100%; /* 尽量大 */
    max-width: 372px; /* 不能过大 */
  }
}
</style>