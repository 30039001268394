<template>
  <el-card style="border-radius: 12px;backdrop-filter: blur(10px)">
    <div class="card-body">
      <div class="icon">{{ icon }}</div>
      <el-text class="title">{{ title }}</el-text>
      <el-text type="info" line-clamp="2" class="detail">{{ detail }}</el-text>
    </div>
  </el-card>
</template>
<script>
export default {
  name: "FeatureCard",
  props: {
    icon: String,
    title: String,
    detail: String,
  }
}
</script>

<style scoped>
.title {
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
}
.icon {
  --card-icon-bg-c: rgba(142, 150, 170, .14);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 6px;
  background-color: var(--card-icon-bg-c);
  width: 48px;
  height: 48px;
  font-size: 24px;
}
/* ' '空格为后代选择器：选择所有处于html.dark元素内部的.el-card类元素，不论.el-card元素与html.dark元素之间有多少层嵌套 */
html.dark .icon {
  --card-icon-bg-c: rgba(101, 117, 133, .16);
}
.card-body {
  display: flex;
  flex-direction: column;
}
/* 直接子代选择器 */
.card-body > * {
  align-self: flex-start;
}
.detail {
  flex-grow: 1;
  padding-top: 8px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
}
</style>