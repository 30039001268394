<template>
  <div class="home">
    <div class="hero-container">
      <div class="hero-text-container">
        <el-text class="hero-text" type="primary">蔡姬や</el-text>
        <el-text class="hero-text">开发者中心</el-text>
        <el-text class="tagline" type="info" tag="b" style="">
          用代码编织世界，以字节转译人间。
        </el-text>
        <div class="actions">
          <el-button type="primary" round size="large" tag="a" href="https://blog.cls.ink" target="_blank">
            博客
          </el-button>
          <el-button round size="large" tag="a" href="https://github.com/MrBeanCpp" target="_blank">
            Github
          </el-button>
        </div>
      </div>
      <!-- 父元素设为relative，才能为子元素的absolute定位提供上下文 -->
      <div style="position: relative; width: 300px; height: 300px; margin: auto; flex-grow: 1">
        <div class="hero-img-bg" style="width: 280px; height: 280px;"/>
        <img class="hero-img-src" alt="Vue logo" src="../assets/cls-logo.png">
      </div>
    </div>
    <Features/>
  </div>
</template>

<script>
import Features from "@/components/Features.vue";

export default {
  name: 'HomeView',
  components: {Features},
}
</script>

<style scoped>
.home {
  padding: var(--container-padding);
  max-width: 1152px; /* 宽度不包括边距 */
  margin: 0 auto; /* 水平居中 */
}
.hero-text {
  line-height: 64px;
  font-size: 56px;
  font-weight: 700;
  align-self: flex-start;
}
.tagline {
  line-height: 36px;
  font-size: 24px;
  padding-top: 12px;
  align-self: flex-start;
}
.actions{
  display: flex;
  padding-top: 32px;
}
.hero-img-bg {
  z-index: -1; /* 置于底层 */
  border-radius: 50%;
  background: linear-gradient( -45deg, #bd34fe 50%, #47caff 50% );
/*  background: radial-gradient(circle, #ffffff 20%, #2c4be1 70%);*/
  filter: blur(72px);

  /* 绝对定位居中，才能和图片重叠 */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
/* 增强中心光效 */
.hero-img-bg::before {
  content: '';
  position: absolute;
  /* move to center */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;
  background: radial-gradient(#ffffff 35%, transparent 50%);
}
/* "hero image"（英雄图像）是指放置在网页顶部、通常用于吸引访问者注意的大型横幅图片或图像区域 */
.hero-img-src {
  position: absolute;
  left: 50%;  /* 50% of parent */
  top: 50%;
  transform: translate(-50%, -50%); /* 50% of self */
}
a {
  text-decoration: none;
}
.hero-container {
  display: flex;
  flex-direction: row;
  margin: 50px auto;
}
.hero-text-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center
}

/* 响应式布局，for Mobile  https://juejin.cn/post/6844903814332432397 */
/* 写在下面优先级高 */
/* !CSS像素默认是设备独立像素（逻辑像素）所以不用担心Retina屏幕 or 4K(?) ！https://blog.csdn.net/m0_71485750/article/details/126039254 */
@media (max-width: calc(992px - 1px)){
  .hero-container {
    flex-direction: column-reverse;
  }
  .hero-text, .tagline{
    align-self: center;
  }
  .hero-text {
    line-height: 56px;
    font-size: 48px;
  }
  .tagline {
    line-height: 32px;
    font-size: 20px;
  }
  .actions {
    justify-content: center; /* 主轴（默认水平）方向 */
  }
}

@media (max-width: calc(768px - 1px)) {
  .hero-text {
    line-height: 40px;
    font-size: 32px;
  }
  .tagline {
    line-height: 28px;
    font-size: 18px;
  }
}
</style>
