<template>
  <!-- 100vh才能让footer触底 -->
  <el-container style="min-height: 100vh">
    <!-- sticky header -->
    <el-header class="header">
      <div class="header-content">
        <router-link  to="/" class="flex-align-center" style="text-decoration: none">
          <!-- 这里的src是自定义属性，没有被webpack处理，需要以变量(require)形式引入，不能直接写路径 -->
          <!-- https://blog.csdn.net/weixin_44634727/article/details/121785353  -->
          <!-- 这里会有警告：`类型 {} 不可分配给类型 string`，很烦，应该是ts的内容，可以关闭"检查-常规-类型不匹配" -->
          <el-avatar :src="require('@/assets/good job.jpg')"/>
          <el-text size="large" tag="b" style="margin-left: 10px">MrBeanC</el-text>
        </router-link>
        <div class="header-end">
          <el-menu :default-active="active_menu" router style="border-bottom: none; background: transparent" mode="horizontal" :ellipsis="false">
            <el-menu-item index="/">首页</el-menu-item>
            <el-menu-item index="/about" style="font-weight: bold">关于</el-menu-item>
            <!-- TODO 可以改用json文件 + for循环渲染 -->
            <el-sub-menu class="hidden-xs-only" index=""> <!-- 不设置index会报错 故用""占位 -->
              <template #title>资源</template>
              <el-menu-item-group>
                <template #title>JS框架</template>
                <el-menu-item index="">
                  <a class="menu-link" href="https://cn.vuejs.org/" target="_blank">Vue.js</a>
                </el-menu-item>
                <el-menu-item index="" >
                  <a class="menu-link" href="https://router.vuejs.org/zh/" target="_blank">Vue Router</a>
                </el-menu-item>
              </el-menu-item-group>
              <el-divider class="menu-divider-h"/>
              <el-menu-item-group>
                <template #title>UI框架</template>
                <el-menu-item index="">
                  <a class="menu-link" href="https://element-plus.gitee.io/zh-CN/" target="_blank">Element-Plus</a>
                </el-menu-item>
                <el-menu-item index="">
                  <a class="menu-link" href="https://iconpark.oceanengine.com/official" target="_blank">Icon-Park</a>
                </el-menu-item>
              </el-menu-item-group>
              <el-divider class="menu-divider-h"/>
              <el-menu-item-group>
                <template #title>其他</template>
                <el-menu-item index="" >
                  <a class="menu-link" href="https://developer.hitokoto.cn/" target="_blank">一言（ひとこと）</a>
                </el-menu-item>
              </el-menu-item-group>
            </el-sub-menu>
          </el-menu>
          <el-divider direction="vertical"/>
          <ThemeSwitch/>
          <div class="flex-align-center hidden-xs-only"><!-- 小于768px自动隐藏 -->
            <el-divider direction="vertical"/>
            <a href="https://space.bilibili.com/27070020" target="_blank" class="flex-center">
              <!-- 加上fill="currentColor" 才会继承父类的color -->
              <svg class="icon" fill="currentColor" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                <path d="M306.005333 117.632L444.330667 256h135.296l138.368-138.325333a42.666667 42.666667 0 0 1 60.373333 60.373333L700.330667 256H789.333333A149.333333 149.333333 0 0 1 938.666667 405.333333v341.333334a149.333333 149.333333 0 0 1-149.333334 149.333333h-554.666666A149.333333 149.333333 0 0 1 85.333333 746.666667v-341.333334A149.333333 149.333333 0 0 1 234.666667 256h88.96L245.632 177.962667a42.666667 42.666667 0 0 1 60.373333-60.373334zM789.333333 341.333333h-554.666666a64 64 0 0 0-63.701334 57.856L170.666667 405.333333v341.333334a64 64 0 0 0 57.856 63.701333L234.666667 810.666667h554.666666a64 64 0 0 0 63.701334-57.856L853.333333 746.666667v-341.333334A64 64 0 0 0 789.333333 341.333333zM341.333333 469.333333a42.666667 42.666667 0 0 1 42.666667 42.666667v85.333333a42.666667 42.666667 0 0 1-85.333333 0v-85.333333a42.666667 42.666667 0 0 1 42.666666-42.666667z m341.333334 0a42.666667 42.666667 0 0 1 42.666666 42.666667v85.333333a42.666667 42.666667 0 0 1-85.333333 0v-85.333333a42.666667 42.666667 0 0 1 42.666667-42.666667z"></path>
              </svg>
            </a>
            <a href="https://github.com/MrBeanCpp" target="_blank" class="flex-center">
              <icon-park type="github-one" size="20" class="icon"/>
            </a>
          </div>
        </div>
      </div>
    </el-header>
    <el-container>
      <Aside v-if="showAside" class="hidden-xs-only"/>
      <!--  防止hero-img-bg的效果被截断  -->
      <el-main style="overflow: visible">
        <router-view/>
      </el-main>
    </el-container>
    <el-footer class="footer" v-if="isHomePage" style="height: fit-content;">
      <el-text type="info" style="font-weight: 500">
        本文档遵循 cls 协议<br>
        © 2024 MrBeanC All Rights Reserved.
      </el-text>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      active_menu: '/',
    }
  },
  methods: {
  },
  watch: {
    $route() { // 监听路由变化， '$route.path'() 也可以
      // 将路由变化（如通过鼠标侧键返回）反映到菜单上 /* https://blog.csdn.net/qq_38057434/article/details/95480933 */
      this.active_menu = this.$route.path;
    }
  },
  computed: {
    isHomePage() {
      return this.$route.path === '/';
    },
    showAside() {
      return this.$route.path !== '/';
    }
  }
}
</script>

<!--  `<script setup>`语法 可以自动暴露顶层组件
      对于`<script>`
      仅通过`components`声明的icon组件，可以通过<View/>形式使用
      但是需要显式通过`data()` | `computed()`暴露 才能在`:active-action-icon`中使用 //组件和变量的区别？
-->
<script setup>
import ThemeSwitch from "@/components/ThemeSwtich.vue";
import {IconPark} from '@icon-park/vue-next/es/all';
import Aside from "@/components/Aside.vue";
</script>

<style>
.header {
  border-bottom: 1px solid var(--el-border-color);
  padding: 0 32px !important;

  position: sticky;
  top: 0;
  z-index: 1000;

  /* 从中心开始到1px：透明，从1px开始#141414 */
  /* 其实完整形式应该是 `radial-gradient(transparent 0, transparent 1px, #141414 1px)` */
  /* Vue3 引入了 CSS v-bind 绑定变量（不过会导致和全局theme切换速度不一致）；或者直接用Element-Plus的变量 var(--el-bg-color) */
  background-image: radial-gradient(transparent 1px, var(--el-bg-color) 1px);
  /* 以 4*4 的大小铺满 */
  background-size: 4px 4px;
  /* 背景模糊滤镜 */
  backdrop-filter: saturate(50%) blur(4px);
}
.header-content {
  display: flex;
  align-items: center;
  max-width: 1376px;
  margin: 0 auto;
}
.header-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* 默认为0，则在容器大于元素的情况下 不会主动填充 导致flex-end无效 */
  flex-grow: 1;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer {
  border-top: 1px solid var(--footer-border-color);
  padding: 32px !important;
}

:root {
  /* Segoe UI Emoji 用于 Emoji 图标*/
  --main-font: "Noto Sans SC", "Segoe UI Emoji", sans-serif; /* Roboto */
  --icon-color: rgb(96,98,102);
  --footer-border-color: #e2e2e3;
}
#app {
/*  font-family: var(--main-font);*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
a.menu-link {
  color: inherit;
  text-decoration: inherit; /* 继承 */
  flex-grow: 1; /* 宽度占满flex父容器 */
}
a.menu-link::after {
  margin-left: 4px;
  display: inline-block; /* <a>:after默认是inline，不能设置宽高，block可以 但是会另起一行，inline-block结合两者，可以同一行 */
  content: ''; /* 必要 占位 */
  width: 11px; /* 要和svg中的size对应 */
  height: 11px;
  /* 改用字体图标可能更方便(content) */
  /* svg以文件引入，就不能通过color修改颜色了 */
  background: url("@/assets/icons/arrow-right-up.svg"); /* 这张svg改颜色不能用fill 因为没有闭合曲线，只能改stroke */
}
.menu-divider-h {
  margin: 6px auto !important;
}

body {
  /* 保证header top = 0 */
  margin: 0;
  padding: 0;
  font-family: var(--main-font);

  /* !IMPORTANT!: 防止屏幕宽度过小，无限压缩html body，出现滚动条，导致页面>视口，导致各种元素（如footer）不能占满页面（只能占满窗口） */
  /* min-width: 404px; !* 具体数值参照页面中不能被压缩的元素来定，如图片，单行文字 *!*/
  min-width: fit-content; /* 根据页面内容自适应最小width，保证body不会小于图片 产生滚动条 */

  /* 为了避免在移动设备上浏览器横屏时调整字体大小（如：footer）： https://blog.csdn.net/qq_33072593/article/details/78585526 */
  text-size-adjust: none;
}
</style>

<style scoped>
.icon {
  color: var(--icon-color) !important;
  margin: 0 6px;
  transition: color 0.3s ease; /* 过渡动画 */
}
.icon:hover {
  color: var(--el-color-primary) !important;
}
</style>