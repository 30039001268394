import { createApp, version } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import hljs from 'highlight.js';
import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index';
import '@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css';

import ElementPlus from 'element-plus'
import '@icon-park/vue-next/styles/index.css';
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/theme-chalk/dark/css-vars.css'

import './assets/styles/common.css'
import './assets/styles/dark.css'
import './assets/styles/el-override.css'

VMdPreview.use(githubTheme, {
    Hljs: hljs,
});
VMdPreview.use(createTodoListPlugin());

console.log('Vue version:', version)

createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus)
    .use(VMdPreview)
    .mount('#app')
