<template>
  <!-- @change只会响应用户交互，不会响应js代码对isDark的修改 -->
  <el-switch ref="theme-btn" v-model="isDark" :inactive-action-icon="Sunny" style="margin: 0 12px" @change="doThemeChange">
    <!-- Element-PLus自带的moon不太好，只能酱紫了 -->
    <template #active-action>
      <!-- 通过svg文件 + <img>引入，如果文件不包含`xmlns`就会加载失败-->
      <!-- 但是，如果通过svg文件引入，就无法通过fill or color去切换图标颜色 -->
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
        <path fill="rgb(207,211,220)" d="M42.108 29.769c.124-.387-.258-.736-.645-.613A17.99 17.99 0 0136 30c-9.941 0-18-8.059-18-18 0-1.905.296-3.74.844-5.463.123-.387-.226-.769-.613-.645C10.558 8.334 5 15.518 5 24c0 10.493 8.507 19 19 19 8.482 0 15.666-5.558 18.108-13.231z"/>
      </svg>
    </template>
  </el-switch>
</template>

<script>

export default {
  name: "ThemeSwitch",
  data() {
    return {
      isDark: false,
    }
  },
  methods: {
    /** 根据系统偏好和本地数据综合判断 */
    useDark() {
      const preferDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const localDark = localStorage.getItem('dark-theme'); // String | null
      console.log("preferDark: ", preferDark)
      console.log("local Dark-Theme: ", localDark)
      return localDark === null ? preferDark : localDark === 'true'; // 本地存储优先
    },
    toggleTheme(isDark) {
      /* add/remove class='dark' in <html> */
      document.documentElement.classList.toggle('dark', isDark);
    },
    doThemeChange(dark) {
      /* ↓可以看到只输出了<html>，且上面有伪类::view-transition */
      /* 这个伪类只有在动画过程中才挂载 之后便消失 */
      // console.log(document.querySelector(".dark"));

      // 只在点击按钮时存储theme数据
      localStorage.setItem('dark-theme', dark);

      // 兼容性：有些浏览器不支持动效
      if (!document.startViewTransition) {
        this.toggleTheme(dark);
        return;
      }

      const button = this.$refs["theme-btn"];
      const rect = button.$el.getBoundingClientRect();
      const x = rect.left + rect.width / 2;
      const y = rect.top + rect.height / 2;

      let transition = document.startViewTransition(() => {
        this.toggleTheme(dark);
      });
      /* VueUse、View Transitions API实现暗黑模式主题动画切换 https://blog.csdn.net/qq_43775179/article/details/134284164 */
      /* https://developer.chrome.com/docs/web-platform/view-transitions?hl=zh-cn */
      /* https://wtto00.github.io/posts/dark-mode-spread-animation/ */
      transition.ready.then(() => {
        // 找到距离(x,y)最远角
        const endRadius = Math.hypot( // 平方根：直角三角形斜边长
            Math.max(x, innerWidth - x),
            Math.max(y, innerHeight - y)
        );
        /* 圆形扩散动画 */
        const clipPath = [
          `circle(0 at ${x}px ${y}px)`,
          `circle(${endRadius}px at ${x}px ${y}px)`,
        ];
        document.documentElement.animate(
            {
              clipPath: dark ? clipPath : [...clipPath].reverse(),
            },
            {
              duration: 500,
              easing: 'ease-in',
              pseudoElement: dark ? '::view-transition-new(root)' : '::view-transition-old(root)',
            }
        );
      });
    }
  },
  created() {
    this.isDark = this.useDark(); // 不会触发el-switch的@change事件
    this.toggleTheme(this.isDark); // 初始化时不能存储theme数据，所以不能监听isDark & store
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      console.log("System Theme Changed, isDark:", e.matches);
      this.isDark = this.useDark();
      this.toggleTheme(this.isDark);
    });
  }
}
</script>

<script setup>
import {Sunny} from '@element-plus/icons-vue'
</script>

<!-- 不能加scoped 否则作用不到html， & 不能用`//`注释，否则会导致css失效（但是不会报错） -->
<style>
/** Animated Theme Toggle */
/* 禁用检查-CSS-无效的伪选择器 */
::view-transition-old(root),
::view-transition-new(root) {
  animation: none;
  mix-blend-mode: normal;
}
/* 切换为dark时，dark在上，dark由内扩散 */
/* 默认应该是挂载在<html>上，<html> == root ? */
.dark::view-transition-old(root) {
  z-index: 1;
}
.dark::view-transition-new(root) {
  z-index: 999;
}
/* 切换为while时，old dark还是在上，由外往内收缩，dark永远在上，保持一致性 */
::view-transition-old(root) {
  z-index: 999;
}
::view-transition-new(root) {
  z-index: 1;
}
</style>