<template>
  <el-aside class="aside">
    <el-menu default-active="2" :unique-opened="true">
      <el-sub-menu index="1">
        <template #title>
<!--          <el-icon><location /></el-icon>-->
          <span>Navigator One</span>
        </template>
        <el-menu-item-group title="Group One">
          <el-menu-item index="1-1">item one</el-menu-item>
          <el-menu-item index="1-2">item two</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group title="Group Two">
          <el-menu-item index="1-3">item three</el-menu-item>
        </el-menu-item-group>
        <el-sub-menu index="1-4">
          <template #title>item four</template>
          <el-menu-item index="1-4-1">item one</el-menu-item>
        </el-sub-menu>
      </el-sub-menu>
      <el-menu-item index="2">
<!--        <el-icon><icon-menu /></el-icon>-->
        <span>Navigator Two</span>
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>

<script>
export default {
  name: "Aside"
}
</script>

<style scoped>
.aside {
  height: calc(100vh - 60px); /* vh: view height */
  position: sticky;
  top: 60px;
}
</style>